import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Card from 'react-bootstrap/Card';

import RunwaysForm from './runwaysForm';
import RunwaysResult from './runwaysResult';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles.css";


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rwys: [],
      wind: 1,
      isWindValid: true
    };
  }

  handleOnChangeRwys(rwys) {
    this.setState({
      ...this.state,
      rwys
    });
  }

  handleOnChangeWind(event) {
    this.setState({
      ...this.state,
      wind: event.target.value,
      isWindValid: event.target.value > 0 && event.target.value < 360
    });
  }

  render() {
    return (
      <div className="App">
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">Which runway to use?</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Navbar>
        <br/>
        <Container>
          <Row>
            <Col>
              <Card className="mainCard p-2">
              <Card.Title>
                  Wind:
                </Card.Title>
                <Card.Body>
                  <Form>
                    <Form.Control
                          type="number"
                          value={this.state.wind}
                          onChange={this.handleOnChangeWind.bind(this)}
                          className={ this.state.isWindValid ? " " : "is-invalid" }
                        />
                        <div className={this.state.isWindValid ? "hidden" : "inline-errormsg" }>
                          Wind must be between 0 and 360 degrees
                        </div>
                  </Form>
                </Card.Body>
                <Card.Title>
                  Runways available:
                </Card.Title>
                <Card.Body>
                  <RunwaysForm onSomething={this.handleOnChangeRwys.bind(this)}/>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <RunwaysResult wind={this.state.wind} runways={this.state.rwys} />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <Card className="p-2" >
                <Card.Header>
                  How to calculate the best runway to use based on wind?
                </Card.Header>
                <Card.Body>
                  Take a wind, round it to decimal and divide by 100 (246° => 25).
                  Then take your runways and calculate absolute value for subtraction
                  of wind and runway number. You can do this by simply subtracting
                  lower number from the higher. The one with the lowest difference wins.

                  <br/>
                  Example:
                  <br/>
                  Runways are 06/24 and 13/31, wind is 186°.
                  <ul>
                    <li>19 - 6 = 15</li>
                    <li>19 - 13 = 6</li>
                    <li>24 - 19 = 5</li>
                    <li>31 - 19 = 12</li>
                  </ul>
                  So runway 24 looks the best, yet 13 is just little worse.
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    );
  }
}


export default App;
