import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import 'bootstrap/dist/css/bootstrap.min.css';


class RunwaysForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rwys : [{
        direction: 0,
        both: false
      }]
    };
  }

  changeUsableDirections(rwyIndex) {
    let rwys = [...this.state.rwys];
    let rwy = {...this.state.rwys[rwyIndex]};
    rwy.both = !rwy.both;
    rwys[rwyIndex] = rwy;

    this.fireOnChange(rwys);
    this.setState({rwys});
  }

  handleDirectionChange(rwyIndex, newValue) {
    let rwys = [...this.state.rwys];
    let rwy = {...this.state.rwys[rwyIndex]};
    rwy.direction = parseInt(newValue);
    rwys[rwyIndex] = rwy;
    this.fireOnChange(rwys);
    this.setState({rwys});
  }

  addRwy() {
    let rwys = [...this.state.rwys, {direction: 0, both: false}];
    this.fireOnChange(rwys);
    this.setState({rwys});
  }

  fireOnChange(newRwys) {
    let rwys = [];
    for(const [, value] of newRwys.entries()) {
      rwys.push(value.direction);
      if(value.both) {
        rwys.push((value.direction + 18) % 36);
      }

    }
    this.props.onSomething(rwys);
  }

  render() {
    let items = [];
    for(const [index, value] of this.state.rwys.entries()) {
      let otherDirection = undefined;

      let isValid = (value.direction >= 0 && value.direction <= 36);
      if(isValid && value.both) {
        otherDirection = <Form.Control readOnly type="number" value={(value.direction + 18) % 36}/>;
      }
      else {
        otherDirection = <Form.Control readOnly type="number" value=''/>;
      }

      let plusButton = undefined;
      if(index === this.state.rwys.length - 1) {
        plusButton = <Button onClick={() => this.addRwy()}>+</Button>
      }

      items.push(
        <Row>
          <Col>
            <Form.Control
              type="number"
              value={this.state.rwys[index].direction}
              onChange={(event) => { this.handleDirectionChange(index, event.target.value)} }
              className={isValid ? "no" : "is-invalid"}
            />
            <div className={isValid ? "hidden" : "inline-errormsg" }>
              Runway direction must be between 0 and 36
            </div>
          </Col>
          <Col><Form.Check label="Both directions" onChange = {() => { this.changeUsableDirections(index)} } /></Col>
          <Col>{otherDirection}</Col>
          <Col>{plusButton}</Col>
        </Row>
      )
    }
    return (
      <div className="mainCard">
        <Container className="mainCard">
          {items}
        </Container>
      </div>
    );
  }
}


export default RunwaysForm;
