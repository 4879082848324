
import React from 'react';
import Card from 'react-bootstrap/Card';

import 'bootstrap/dist/css/bootstrap.min.css';

class RunwayResult extends React.Component {

  getRunway(rwys, wind) {
    let bestRwy = undefined;
    let bestDiff = 360;
    for(let rwy of rwys) {
      let rwyDirection = rwy * 10;
      let diff = Math.abs(wind - rwyDirection);

      if(diff < bestDiff) {
        bestDiff = diff;
        bestRwy = rwy;
      }
    }
    if(!bestRwy)
      bestRwy = '?'

    return bestRwy;
  }

  render() {

    return (
      <Card className="p-2 mainCard">
      <Card.Title>Best runway:</Card.Title>
      <h3>{this.getRunway(this.props.runways, this.props.wind)}</h3>
      </Card>
    );
  }
}


export default RunwayResult;
